/* テーブルの先頭行 */
div.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    font-weight: bold;
}

p.MuiTablePagination-displayedRows {
    font-size: 18px;
    font-weight: bold;
    color:aliceblue;
}

/* 左右両方のアイコンに色を適用 */
.MuiTablePagination-actions svg path {
    fill: aliceblue;
    /* 任意の色に変更可能 */
}